<template>
    <div>
        <Modal :options="{width: '40vw', type: 'warning'}">
            <div class="title text-white">Mensaje inportante</div>
            <div class="h6 p-3">
                ¿Por qué motivo deseas actualizar los datos de la queja? Por favor, explícalo brevemente en el campo
                correspondiente.
            </div>
            <form action="">
                <div class="body">
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <label for="motivo">Descripción</label>
                            <textarea v-model="event.motivo" name="motivo" id="motivo" cols="30" rows="5"
                                class="form-control" />
                        </div>

                    </div>
                </div>
                <div class="footer">
                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <button class="btn btn-primary mr-2" type="button" @click.prevent="onSubmit">
                              <i class="fa-solid fa-check"></i>
                              Confirmar
                            </button>
                            <button class="btn btn-danger" type="button" @click.prevent="$emit('close_evento')">
                              <i class="fa-solid fa-xmark"></i>
                              Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    </div>
</template>
<script>

import Modal from '@/components/Modal';

export default {
    components: { Modal },
    data() {
        return {
            event: {
                nombre: 'actualizacion_de_queja',
                motivo: null
            }
        }
    },
    methods: {
        onSubmit() {
            this.$emit("queja_evento", this.event)
        }
    }
}
</script>